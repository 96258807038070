import React from 'react'
import Layout from '../components/layout/BaseLayout'
import content from '../l10n/data'

const NotFoundPage = () => (
  <Layout>
    <h1>{content.notFound}</h1>
    <p>{content.notFoundMessage}</p>
  </Layout>
)

export default NotFoundPage
